import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.localStorage.getItem("token") ?? token;
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [globalSearch, setGlobalSearch] = useState("");
  const [searchIt, setSearchIt] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalpages] = useState(1);

  const [auth, setAuth] = useState();
  const [allCategory, setAllCategory] = useState({});

  const token = localStorage.getItem("token");

  const getProfileData = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getUserProfile,
        headers: {
          token: token,
        },
      });
      if (res?.data?.responseCode === 200) {
        setProfileData(res?.data?.result);
        setLoading(false);
      } else if (res?.data?.responseCode === 401) {
        localStorage.removeItem("token");
      }
    } catch (error) {
      // console.log(error, "jkhsjksdfhg");
      if (error?.response?.data?.responseCode == 401) {
        window.location.reload();
        history.push("/");
        toast.error(error?.response?.data?.responseMessage);
        localStorage.removeItem("token");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchIt]);


  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfileData();
    }
  }, [localStorage.getItem("token"), isLogin]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    profileData,
    loading,
    setLoading,
    setIsLogin,
    setProfileData,
    setGlobalSearch,
    setSearchIt,
    allCategory,
    searchIt,
    globalSearch,
    totalPages,
    page,
    setPage,
    setTotalpages,
    auth,
    getProfileData: getProfileData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
