import React, { useEffect, useState, useContext, useMemo } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List, Button, makeStyles} from "@material-ui/core";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import { MdOutlineAccountBalanceWallet, MdOutlineSubscriptions } from "react-icons/md";
import CommonPopup from "src/views/pages/Dashboard/Watchlist/CommonPopup";
import { FaRegUser } from "react-icons/fa";
import { SlBadge } from "react-icons/sl";
import { IoMdNotificationsOutline } from "react-icons/io";
import { PiTicketThin } from "react-icons/pi";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";
import { IoCreateOutline } from "react-icons/io5";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#fff",
  },
  desktopDrawer: {
    "& .logoutBtnContainer":{
      height:'208px',
      width:'250px',
      position:'absolute',
      bottom:'0',
      background:'#fff',
    },
    background: "#fff",
    marginTop: "90px",
    minWidth: "250px",
    height:"calc(100% - 90px)",
    // overflow:'scroll',
    // scrollbarWidth:'thin',
    // scrollBehavior:"smooth",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  chPassButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "10px",
    width: "220px",
    marginLeft: "11px",
    position: "absolute",
    border: "1px dashed",
    bottom: "80px",
    background: "transparent",
    fontWeight: "400",
    padding: "13px 20px",
    fontSize: "13px",
    [theme.breakpoints.down("md")]: {
      bottom: "-152px",
    },
  },
  chPassButton1: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "10px",
    width: "220px",
    marginLeft: "11px",
    position: "absolute",
    border: "1px dashed",
    background: "transparent",
    fontWeight: "400",
    padding: "13px 20px",
    fontSize: "13px",
    bottom: "140px",
    [theme.breakpoints.down("md")]: {
      bottom: "-90px",
    },
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "10px",
    width: "220px",
    marginLeft: "11px",
    position: "absolute",
    border: "1px dashed",
    bottom: "20px",
    background: "transparent",
    fontWeight: "400",
    padding: "13px 20px",
    fontSize: "13px",
    [theme.breakpoints.down("md")]: {
      bottom: "-213px",
    },
  },
  sideMenuBox: {
    height:"calc(100% + 200px)",
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  navDetail: {
    color: theme.palette.secondary.main,
    borderRadius: "15px",
    background: "linear-gradient(149deg, #5681F1 10.75%, #1354FC 94.05%)",
    padding: "15px",
    boxShadow: "1px 21px 13px -13px rgba(19,84,252,0.75)",
    "& .avtarBox": {
      gap: "10px",
    },
    "& h6": {
      color: theme.palette.secondary.main,
    },
    "& hr": {
      backgroundColor: "rgba(255, 255, 255, 0.10)",
      margin: "10px 0",
    },
  },
  dailogOpen: {
    "& .MuiPaper-rounded": {
      borderRadius: "15px",
      padding: "50px",
    },
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "70px",
    },
    "& p": {
      fontSize: "18px",
      fontWeight: 400,
      color: theme.palette.primary.light,
      marginTop: "20px",
    },
    "& h3": {
      fontSize: "30px",
      marginTop: "43px",
    },
  },
}));

const sections = [
  {
    items: [
      {
        title: "My Wallet",
        modules: "dashboard",
        icon: MdOutlineAccountBalanceWallet,
        href: "/profile/my-wallet",
      },
      {
        title: "My Profile",
        modules: "dashboard",
        icon: FaRegUser,
        href: "/profile",
      },
      {
        title: "Rewards",
        modules: "dashboard",
        icon: FaRegUser,
        href: "/profile/rewards",
      },
      {
        title: "Create Skill Drops",
        modules: "dashboard",
        icon: IoCreateOutline,
        href: "/profile/create-skill-drops",
      },
      // {
      //   title: "My Resume",
      //   modules: "dashboard",
      //   icon: BsLayoutTextWindowReverse,
      //   href: "/my-resume",
      // },

      // {
      //   title: "My Skills",
      //   modules: "dashboard",
      //   icon: ListIcon,
      //   href: "/skills",
      // },
      // {
      //   title: "Create Course NFT",
      //   modules: "dashboard",
      //   icon: SlBookOpen,
      //   href: "/course-nft",
      // },
      // {
      //   title: "My Course NFT",
      //   modules: "dashboard",
      //   icon: MdOutlineSubscriptions,
      //   href: "/subscription-nft",
      // },
      // {
      //   title: "Opportunities",
      //   modules: "dashboard",
      //   icon: IoBagRemoveSharp,
      //   href: "/opportunity",
      // },
      {
        title: "My Courses",
        modules: "dashboard",
        icon: MdOutlineSubscriptions,
        href: "/profile/my-subscription",
      },
      {
        title: "My Skill Drops",
        modules: "dashboard",
        icon: MdOutlineSubscriptions,
        href: "/profile/my-drops",
      },
      {
        title: "My Skill Badges",
        modules: "dashboard",
        icon: SlBadge,
        href: "/profile/skill-badges",
      },
      {
        title: "My Notifications ",
        modules: "dashboard",
        icon: IoMdNotificationsOutline,
        href: "/profile/notifications",
      },
      {
          title: "Refer & Earn",
          modules: "dashboard",
          icon: GroupOutlinedIcon,
          href: "/profile/referral",
        },
      {
        title: "My tickets",
        modules: "dashboard",
        icon: PiTicketThin,
        href: "/profile/my-tickets",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [isdelete1, setIsDelete1] = useState(false);
  const userRole = auth?.profileData?.role;
  const handleLogoutClose = () => {
    setIsLogout(false);
  };
  const handleDeleteClose = () => {
    setIsDelete(false);
  };
  const handleDeleteClose1 = () => {
    setIsDelete1(false);
    history.push("/");
  };
  const DeleteProfile = async () => {
    try {
      const res = await axios({
        url: apiConfig.deleteProfile,
        method: "Delete",
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (res?.data?.responseCode === 200) {
        localStorage.removeItem("token");
        setIsDelete1(true);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const filteredSections = useMemo(() => {
    return sections.map((section) => ({
      ...section,
      items: section.items.filter((item) => {
        if (userRole !== "Educator" && item.title === "Create Skill Drops"  ) {
          return false;
        }
        return true; 
      }),
    }));
  }, [userRole]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box style={{ marginBottom: "140px" }}>
        <Box className={classes.sideMenuBox}>
          {
            <List>
              {sections.map((section, i) => {
                let items = section.items;

                return (
                  <div key={`menu${i}`}>
                    {renderNavItems({
                      items: items,
                      pathname: location.pathname,
                    })}
                  </div>
                );
              })}
            </List>
          }
           
        </Box>

        <Box className="logoutBtnContainer">
              <Button
                onClick={() => history.push("/change-password")}
                className={classes.chPassButton1}
                style={{ borderColor: "#C0C0C0", color: "#262626" }}
              >
                <img
                  src="/images/passwordbtn_img.svg"
                  style={{ marginRight: "10px", fontSize: "20px" }}
                />
                Change Password
              </Button>
              <Button
                onClick={() => setIsLogout(true)}
                className={classes.chPassButton}
                style={{ borderColor: "#FF0909", color: "#FF1818" }}
              >
                <img
                  src="/images/logoutbtn_img.svg"
                  style={{ marginRight: "10px", fontSize: "20px" }}
                />
                Logout
              </Button>
              <Button
                onClick={() => setIsDelete(true)}
                className={classes.logoutButton}
                style={{ borderColor: "#FF0909", color: "#FF1818" }}
              >
                <img
                  src="/images/logoutbtn_img.svg"
                  style={{ marginRight: "10px", fontSize: "20px" }}
                />
                Delete Account
              </Button>
        </Box>
      </Box>

      
     
      {isLogout && (
        <CommonPopup
          type="logout"
          open={isLogout}
          onClose={handleLogoutClose}
          title="Logout"
          text="Are you sure you want to log out?"
        />
      )}
      {isdelete && (
        <CommonPopup
          type="delete"
          open={isdelete}
          onClose={handleDeleteClose}
          title="Are You Sure You Want to Delete Your Profile?"
          text="Deleting your profile will remove all your data, certificates, and progress permanently. This action cannot be undone. Do you want to proceed with deleting your profile?"
          RemoveHandler={DeleteProfile}
        />
      )}
      {isdelete1 && (
        <CommonPopup
          type="delete1"
          open={isdelete1}
          onClose={handleDeleteClose1}
          title="Profile Deleted"
          text="Your profile has been successfully deleted. All your data, certificates, and progress have been removed from Gyan Batua. We're sorry to see you go! If you ever decide to return, you're welcome to create a new profile."
          RemoveHandler={DeleteProfile}
        />
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box
          anchor="left"
          className={classes.desktopDrawer}
          open
          variant="persistent"
        >
          {content}
        </Box>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
