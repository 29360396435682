import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import FooterCopyRight from "src/component/FooterCopyRight";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh", // Full viewport height
    backgroundColor: "#eef4f8",
  },
  navBar: {
    flexShrink: 0,
    overflowY: "auto", // Allow NavBar to scroll independently
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "hidden", // Prevent scrolling on wrapper
  },
  topBar: {
    height: "75px", // Adjust height as needed for the TopBar
    flexShrink: 0,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column", // Stack content and footer vertically
    flex: "1 1 auto",
    overflowY: "auto", // Enable vertical scrolling for contentContainer only
  },
  content: {
    flex: "1 1 auto", // Allows content to take up remaining space
    padding: "18px 26px",
    marginTop:"75px",
    [theme.breakpoints.down("md")]: {
      padding: "25px 10px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px",
    },
  },
  footer: {
    padding: "10px 10px 10px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
}));
const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.navBar}>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </div>
      <div className={classes.wrapper}>
        <TopBar className={classes.topBar} onMobileNavOpen={() => setMobileNavOpen(true)} />
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
          <div className={classes.footer}>
            <FooterCopyRight />
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;