import React, { useContext, useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./context/Auth";

export default function SessionLogout({ children }) {
  const history = useHistory();
  const idleTimerRef = useRef();
  const auth = useContext(AuthContext);

  const handleOnIdle = () => {
    console.log("User is idle. Logging out...");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("creatturAccessToken");
    window.localStorage.removeItem("lastActiveTime");
    auth?.setIsLogin(false); 
    history.push("/"); 
  };

  const handleOnAction = () => {
    window.localStorage.setItem("lastActiveTime", Date.now().toString());
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 24 , 
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    idleTimerRef.current = {
      getRemainingTime,
      getLastActiveTime,
    };

    const lastActiveTime = window.localStorage.getItem("lastActiveTime");
    if (lastActiveTime) {
      const currentTime = Date.now();
      const elapsedTime = currentTime - parseInt(lastActiveTime, 10);

      if (elapsedTime > 1000 * 60 * 60 * 24) {
        handleOnIdle();
      }
    }

    window.localStorage.setItem("lastActiveTime", Date.now().toString());
  }, []);

  return <div>{children}</div>;
}
