import React from "react";
import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMediaQuery } from "@material-ui/core";

import {
  Grid,
  Box,
  Typography,
  makeStyles,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  Dialog,
  useTheme,
} from "@material-ui/core";

import { useHistory, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";
import { ImCross } from "react-icons/im";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "19px 0px 0",
    zIndex: "2",
    background: "rgba(8, 86, 204, 0.03)",
    "& .subsribeBtn": {
      width: "100%",
      maxWidth: "154px",
    },
    "& .Gimages": {
      width: "364px",
      marginLeft: "-4px",
      "& .googlePlay": {
        width: "45%",
        height: "60px",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        alignItems: "start",
        width: "296px",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        alignItems: "start",
        width: "280px",
      },
    },
    "& .communityContainer": {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      margin: "0 auto 18px auto",
      "& .SubcommunityContainer": {
        width: "70%",
        "@media (max-width:480px)": {
          width: "100%",
        },
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto 18px auto",
      },
      padding: "23px 0",
      background: "#fff",
      borderRadius: "20px",
      width: "calc(100% - 100px)",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
        padding: "23px",
      },
      "& .textdata": {
        maxWidth: "800px",
        "& h3": {
          fontSize: "30px",
          fontWeight: 500,
          [theme.breakpoints.down("xs")]: {
            fontSize: "24px !important",
            lineHeight: "28px !important",
            paddingBottom: "15px",
          },
        },
        "& p": {
          color: theme.palette.text.primary,
          width: "100%",
          maxWidth: "681px",
          fontSize: "14px",
        },
        "& .textfieldAndButton": {
          marginTop: "16px",
          "& .emailTextfield": {
            width: "100%",
            maxWidth: "336px",
            marginRight: "10px",

            [theme.breakpoints.down("md")]: {
              width: "55%",
            },

            "& .MuiOutlinedInput-root": {
              height: "44px",
            },
          },

          "& button": {
            height: "44px",
            width: "100%",
            maxWidth: "153px",
            [theme.breakpoints.down("xs")]: {
              width: "30%",
              fontSize: "14px",
            },
          },
        },
      },

      "& .joinOurCommunity": {
        "& h3": {
          marginBottom: "21px",
          fontSize: "30px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "24px!important",
          },
        },

        "& .iconContainer": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            display: "flex",
          },
        },

        "& .iconButton": {
          padding: "0",
          width: "40px",
          height: "40px",
          border: "1px solid #DDD",
          borderRadius: "3px",
          background: "#FFF",
          marginRight: "10px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "4px",
            width: "30px",
            height: "30px",
          },
          "& span": {
            height: "100%",
          },
        },

        "& .messangerIconButton": {
          position: "absolute",
          top: "-60px",
          display: "none",

          [theme.breakpoints.down("md")]: {
            right: "0",
          },

          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    "& .footerContent": {
      "& .blanckDisplay": {
        "@media(max-width:959px)": {
          display: "none",
        },
      },
      "@media(min-width:1280px)": {
        " & .MuiGrid-container": {
          flexWrap: "nowrap",
        },
      },
      "& .footerSmallContainer": {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        padding: "0 16px",
        rowGap: "16px",
        [theme.breakpoints.down("xs")]: {
          padding: "16px",
        },
      },
      "& .getApp": {
        marginTop: "-120px",
        [theme.breakpoints.down("sm")]: {
          margin: "0",
          padding: "16px",
        },
      },
      boxSizing: "border-box",
      margin: "0 auto",
      background: "#fff",
      padding: "40px",
      width: "calc(100% - 100px)",
      borderRadius: "20px",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 50px) !important",
      },
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 50px) !important",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px",
      },
    },
    "& .gyanbatualogo": {
      width: "80px",
      marginRight: "8px",
      cursor: "pointer",
    },

    "& .trademarks": {
      color: "#262626",
      fontSize: "14px",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "8px",
        lineHeight: "10px",
      },
      "& span": {
        cursor: "pointer",
        color: "#0856CC",
      },
    },
    "& .footerContentBox": {
      "& .aboutgyan": {
        color: "rgba(99, 104, 128, 1)",
        fontFamily: "Roboto Condensed",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
      },
      "& .logoContainer": {
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "center",
        },
      },

      "& p": {
        fontFamily: "Calistoga",
        color: theme.palette.background.default,
        letterSpacing: "0.478px",
      },
      "& .logosideSubHeading": {
        fontSize: "7.785px",
        color: "rgba(255, 255, 255, 0.60)",
        fontFamily: "Outfit",
      },
      "& .footBelowLogoTypo": {
        fontWeight: 300,
      },
      "& h6": {
        color: "rgba(255,255,255,0.60)",
        fontSize: "16px",
        fontWeight: 300,
      },
    },
    "& .secondFooterGrid": {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },

      "& h5": {
        marginBottom: "12px",
        fontFamily: "Roboto Condensed",
        fontSize: "18px !important",
        fontWeight: 500,
        lineHeight: "21.09px",
      },

      "& h6": {
        cursor: "pointer",
      },
      "& p": {
        color: "#575757",
        cursor: "pointer",
        lineHeight: "40px",
      },
      "& a": {
        color: "#575757",
        fontSize: "16px",
      },
    },
    "& .fourthFooterGrid": {
      marginRight: "35px",
      display: "flex",
      height: "100%",
      gap: "6px",
      width: "100%",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },
      "& h5": {
        cursor: "pointer",
        fontFamily: "Roboto Condensed",
        fontSize: "18px !important",
        fontWeight: 500,
        lineHeight: "21.09px",
      },
      "& .boxcont": {
        color: "#575757",
      },
    },
    "& .contactUsBox": {
      "& h5": {
        fontFamily: "Calistoga",
        color: theme.palette.background.default,
        fontWeight: 400,
      },
      "& a": {
        marginBottom: "6px",
        color: "rgba(255, 255, 255, 0.60)",
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "20px",
      },
    },
    "& .copy": {
      fontSize: "12px",
      textAlign: "center",
      fontWeight: "300",
      "& .lowerFooter": {
        flexWrap: "wrap",
        gap: "15px",
        position: "relative",
        padding: "14px 0px",
        "& > div:first-child": {
          display: "flex",
          gap: "12px",
          "& p": {
            fontWeight: "300 !important",
            color: "rgba(255, 255, 255, 0.60)",
          },
        },
        "& > div:nth-child(2)": {
          color: "rgba(255, 255, 255, 0.60)",
          fontFamily: "Sora",
          fontSize: "15.743px",
          fontWeight: 300,
          "& span": {
            fontWeight: 400,
            color: "#fff",
            fontFamily: "Calistoga",
          },
        },
      },
    },

    "& ul": {
      paddingLeft: "0",
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        display: "block",
        "& svg": {
          marginRight: "10px",
          color: "#fe2efe",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "rgba(255 255 255 / 30%)",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
    },
    "& h6": {
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },

    "& a": {
      display: "flex",
      fontSize: "13px",
      alignItems: "center",
      fontWeight: "400",
      paddingLeft: "0px",
      paddingRight: "0px",
      textDecoration: "none",
      color: "#78819F",
      padding: "3px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "11px",
      },
      "&:hover": {
        color: "#EC1F24",
        textDecoration: "none",
        "& svg": {
          color: "red",
          fontSize: "15px",
        },
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .bottomFootertext": {
      width: "calc(100% - 100px)",
      margin: "26px auto 0 auto",
      textAlign: "center",
      paddingBottom: "18px",
    },
  },

  iconbtn: {
    marginTop: "20px",
    "& img": {
      marginTop: "10px",
      marginRight: "30px",
    },
    "& .MuiIconButton-root": {
      marginRight: "8px",
      marginBottom: "8px",
      borderRadious: "10px",
      borderRadius: "7px",
      width: "30px",
      height: "30px",
      padding: "0px",

      "& svg": {
        color: "#395071",
        fontSize: "24px",
        "& :hover": {
          "& svg": {
            color: "#EC1F24",
            fontSize: "24px !important",
          },
        },
      },
    },
  },
  displayCenetr: {
    minWidth: "100px",
    display: "flex",
    justifyContent: "left",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    "& .MuiSelect-icon": {
      fontSize: "20px",
      color: "#262626 !important",
    },

    "& .MuiSelect-select": {
      border: "none",
    },

    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },

    "& .MuiInputBase-input": {
      fontSize: "16px",
      color: "#262626!important",
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    "& .selectContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
      "& > *:first-child": {
        margin: "0 16px 0 0",
      },
    },
  },
  dialogPaper: {
    width: "100%",
    height: "100%",
    maxWidth: "682px",
    maxHeight: "507px",
    borderRadius: "5px",
    "& .passDialogBox": {
      padding: "0px 50px 20px",
    },
    "& .crossIcon": {
      position: "absolute",
      right: "20px",
      paddingTop: "13px",
      cursor: "pointer",
    },
    "& .image1": {
      // mixBlendMode: "hard-light"
    },
    "& .subTypoChangePass": {
      fontFamily: "Roboto Condensed",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "center",
      width: "100%",
      maxWidth: "333px",
    },
    "& .btnOKsubmit": {
      width: "100%",
      maxWidth: "120px",
      fontFamily: "Roboto Condensed",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
    },
    "& .typoChangePass": {
      color: "#262626",
      fontSize: "35px",
      lineHeight: "41px",
      fontWeight: 500,
    },
  },
}));

const formInitialSchema = {
  email: "",
};

const formValidationSchema = Yup.object({
  email: Yup.string()
  .transform((value) => value.trim())
    .email("The email address is invalid.")
    .max(256, "The maximum length for the email address is 256 characters.")
    .required("Please enter the valid email address.")
    .matches(
      /^(?!.*\.\.)(?!.*[@.]$)[a-zA-Z0-9][a-zA-Z0-9._+-]{0,252}@(?=[^.]*[A-Za-z])[a-zA-Z0-9-]{2,63}\.[a-zA-Z]{2,63}$/,
      "The email address is invalid."
    ),
});

export default function Footer() {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscribeUser, setSubscribeUser] = useState();
  const [emailError, setEmailError] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleErrorModalOpen = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };
  const handleSubScribeClick = async (values, resetForm) => {
    if (values.email === "") {
      return;
    }
    let formData = new FormData();
    formData.append("email", values.email);
    try {
      setIsLoading(true);
      const res = await axios.post(apiConfig.subscribeEmail, {
        email: values.email.trim(),
      });

      if (res?.data?.responseCode === 200) {
        setSubscribeUser(res?.data?.responseMessage);
        handleModalOpen();
        resetForm();
      }
      if (res?.data?.responseCode === 409) {
        setEmailError(res?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.responseCode == 409) {
        resetForm();
      }
      handleErrorModalOpen();
    }
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Box className="communityContainer">
          <Box className="SubcommunityContainer">
            <Box className="textdata">
              <Typography variant="h3" color="secondary">
                Don't Miss Out! Join Our Drops & New Courses List!
              </Typography>
              <Typography variant="body2">
                Subscribe now to receive notifications about free Skill Drops
                and newly available courses in the Skills Marketplace. Stay
                informed with the latest updates, tailored content, newsletters,
                and more—customized to your learning and career interests.
              </Typography>
              <Formik
                initialValues={formInitialSchema}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubScribeClick(values, resetForm);
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Box className="textfieldAndButton">
                      <TextField
                        variant="outlined"
                        placeholder="Enter your email"
                        className="emailTextfield"
                        autoComplete="off"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const value = e.target.value
                            .replace(/ {2,}/g, " ")
                            .trimStart();
                          handleChange(e);
                          setFieldValue("email", value);
                          setEmailError("");
                        }}
                        InputProps={{
                          autoComplete: "off",
                          maxLength: 256,
                        }}
                        inputProps={{
                          maxLength: 256,
                        }}
                      />
                      <Button
                        variant="containedPrimary"
                        className="subscribeBtn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Subscribe
                        {loading && (
                          <CircularProgress color="secondary" size="small" />
                        )}
                      </Button>
                    </Box>
                    {touched.email && errors.email && (
                      <FormHelperText error style={{ color: "red" }}>
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Dialog
            open={isModalOpen}
            onClose={handleModalClose}
            classes={{ paper: classes.dialogPaper }}
          >
            <Box className="passDialogBox">
              <ImCross className="crossIcon" onClick={handleModalClose} />
              <Box className="displayCenter">
                <img
                  src="/images/subscribeImage.png"
                  alt=""
                  className="image1"
                />
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  className="typoChangePass displayCenter"
                >
                  Thank you for Subscribing!
                </Typography>
              </Box>
              <Box mt={3} className="displayCenter">
                <Typography variant="body2" className="subTypoChangePass">
                  {" "}
                  We will keep you notified about free Skill Drops, newly available courses in the Skills Marketplace and the latest updates on GyanBatua.AI platform! 
                </Typography>
              </Box>
              <Box className="displayCenter" pt={3}>
              </Box>

              <Box mt={4} className="displayCenter">
                <img src="/images/logo.svg" alt="" height="50px" />
              </Box>
            </Box>
          </Dialog>
          <Dialog
            open={isErrorModalOpen}
            onClose={handleErrorModalClose}
            classes={{ paper: classes.dialogPaper }}
          >
            <Box className="passDialogBox">
              <ImCross className="crossIcon" onClick={handleErrorModalClose} />
              <Box className="displayCenter" style={{ marginTop: "40px" }}>
                <img
                  src="/images/subscribeImage.png"
                  alt=""
                  className="image1"
                />
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  className="typoChangePass displayCenter"
                >
                  You are already subscribed!
                </Typography>
              </Box>
              <Box mt={3} className="displayCenter">
                <Typography variant="body2" className="subTypoChangePass">
                  You are already on our subscription list. Thank you!{" "}
                </Typography>
              </Box>
              <Box className="displayCenter" pt={3}>
              </Box>

              <Box mt={4} className="displayCenter">
                <img src="/images/logo.svg" alt="" height="50px" />
              </Box>
            </Box>
          </Dialog>
          <Box className="joinOurCommunity">
            <Typography variant="h3" color="secondary">
              Join the Community
            </Typography>

            <Box className="iconContainer">
              <IconButton className="iconButton">
                <a
                  href="https://www.facebook.com/gyaanbatua/"
                  target="_blank"
                  className="socialIcons"
                    rel="noopener noreferrer"
                >
                  <img src="/images/fb.svg" alt="Facebook" />
                </a>
              </IconButton>

              <IconButton className="iconButton">
                <a href="https://x.com/GyanBatua" 
                   target="_blank"
                     rel="noopener noreferrer"
                   className="socialIcons">
                  <img src="/images/twitter_footer.svg" alt="Twitter" />
                </a>
              </IconButton>

              <IconButton className="iconButton">
                <a
                  href="https://www.instagram.com/gyanbatua/"
                  target="_blank"
                    rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <img
                    src="/images/insta1.png"
                    style={{ width: "40px", height: "40px" }}
                    alt="Instagram"
                  />
                </a>
              </IconButton>

              <IconButton className="iconButton">
                <a
                  href="https://www.linkedin.com/company/gyan-batua/"
                  target="_blank"
                    rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <img src="/images/linkedIn_footer.svg" alt="LinkedIn" />
                </a>
              </IconButton>

              <IconButton className="iconButton">
                <a
                  href="https://www.youtube.com/@Gyan-Batua"
                  className="socialIcons"
                  target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src="/images/yt_footer.svg" alt="YouTube" />
                </a>
              </IconButton>

              <IconButton className="iconButton">
                <a
                  href="https://in.pinterest.com/GyanBatua/"
                  target="_blank"
                    rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <img src="/images/pinterest_footer.svg" alt="Pinterest" />
                </a>
              </IconButton>

              <IconButton className="messangerIconButton">
                <a
                  href="https://www.messenger.com"
                  target="_blank"
                    rel="noopener noreferrer"
                  className="socialIcons"
                ></a>
                <img src="/images/messanger.svg" alt="Messenger" />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className="footerContent">
          <Grid container spacing={4}>
            {/* =====================image and text=======================  */}
            <Grid
              item
              xs={12}
              sm={6}
              md={7} // md={3}
              lg={6}
              xl={6}
              style={{ justifyContent: "start" }}
            >
              <Box
                className="footerContentBox"
                style={{
                  width:"100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Box mb={4} className="logoContainer">
                    <RouterLink to="/">
                      <img src="/images/logo.svg" alt="" />
                    </RouterLink>
                  </Box>
                  <Typography
                    variant="outlined"
                    style={{}}
                    className="aboutgyan"
                  >
                    GyanBatua.AI makes users aware of the ever-evolving skills needed and connects them with emerging career opportunities in this AI era. Build your personalised Skills Wallet, where you can accumulate Gyan Coins as you learn. Earn rewards for skill achievements, access AI-driven insights, and showcase your verified talents to employers through Blockchain-powered certifications. Gyan Batua also provides intelligent matchmaking, notifying you of career opportunities that match your preferences and skills. Empowering you to thrive in the AI-driven job market, Gyan Batua makes your learning journey more valuable and career-focused.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/*=======================  blank  ======================= */}
            <Grid item md={1} lg={2} xl={2} className="blanckDisplay"></Grid>
            {/*=======================  Explorer ======================= */}
            <Grid
              item
              xs={6}
              sm={3}
              md={2}
              lg={2}
              xl={2}
              style={{marginTop:"20px"}}
              // className="blanckDisplay"
            >
              <Box className="secondFooterGrid" >
                <Typography variant="h5">Explore</Typography>

                <Typography variant="body2">
                  <a href={process.env.REACT_APP_GYAN_CHAIN} target="_blank"   rel="noopener noreferrer" >
                    Gyan Chain
                  </a>
                </Typography>
                <Typography variant="body2">
                  <a href={process.env.REACT_APP_GYAN_KYC} target="_blank"   rel="noopener noreferrer">
                    Gyan KYC
                  </a>
                </Typography>

                <Typography>
                  <a
                    href="/contact-us"
                    onClick={() => history.push("/contact-us")}
                  >
                    Contact Us
                  </a>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              md={2}
              lg={2}
              xl={2}
              style={{marginTop:"20px"}}
              className={classes.displayCenetr}
            >
              <Box className="secondFooterGrid">
                <Typography variant="h5">Company</Typography>
                <Typography
                  variant="body2"
                  onClick={() => history.push("/about-us")}
                >
                  <a href="/about-us">About Us</a>
                </Typography>

                <Typography variant="body2">
                  <a href="/faq">FAQs</a>
                </Typography>
                <Typography variant="body2" >
                  <a href="/policies/privacy-policy">Policies</a>
                </Typography>
              </Box>
            </Grid>

            
          </Grid>
        </Box>

        <Box className="displayCenter bottomFootertext">
          <RouterLink to="/">
            <img src="/images/logo.svg" className="gyanbatualogo"></img>
          </RouterLink>

          <Typography className="trademarks" onClick={() => window.open("https://matrix-secure.in/", "_blank")} >
            <span> © 2024 Matrix Secure Applications (OPC) Pvt. Ltd. All rights reserved.
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
