import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Link,
  Portal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AuthContext } from "src/context/Auth";

import { Link as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#22222233",
    color: theme.palette.common.white,
    maxWidth: 600,
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: "none",
    zIndex: 2000,
  },
  action: {
    background: "linear-gradient(275deg, #0856cc 4.07%, #0856cc 98.21%)",
    color: theme.palette.common.white,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}));

const CookiesNotification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const auth = useContext(AuthContext);
  const [responded, setResponded] = useState(false);
  const handleClose = (choice) => {
    const value = choice === "agree" ? "true" : "false";
    Cookies.set("consent", value, { expires: 30 });
    setOpen(false);
    setResponded(true); 
    if (choice === "decline") {
      localStorage.removeItem("creatturAccessToken");
      localStorage.clear();
      sessionStorage.removeItem("token");
      auth.userLogIn(false, "");
      auth.setProfileData("");
    }
  };

  useEffect(() => {
    const consent = Cookies.get("consent");

    if (!consent) {
      if (window.location.search !== "?app") setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.overlay} style={{ display: responded ? "none" : "block" }}></div>
      <div className={classes.root}>
        <Typography variant="body1" color="inherit">
          We use your information - collected through cookies and similar
          technologies - to improve your experience on our site, analyse how you
          use it and show you personalised advertising. You can find out more in
          our{" "}
          <Link color="inherit" component={RouterLink} to="/privacy-policy">
            Privacy policy.
          </Link>
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => handleClose("agree")}
            variant="contained"
            className={classes.action}
          >
            I Agree
          </Button>
          <Box ml={1}>
            <Button
              onClick={() => handleClose("decline")}
              variant="contained"
              className={classes.action}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
