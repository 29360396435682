import React, { useContext, useState } from "react";

import {
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogActions,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { apiConfig } from "src/apiconfig/ApiConfig";
import axios from "axios";
import { ImCross } from "react-icons/im";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogMain: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: "520px",
      // minHeight: "367px",
      borderRadius: "5px",
    },
    "& .crossIcon": {
      position: "absolute",
      right: "20px",
      paddingTop: "13px",
      cursor: "pointer",
    },
    "& .logoPic": {
      marginTop: "15px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },
    "& .dialogContainer": {
      boxSizing: "border-box",
      padding: "30px",

      "& .imageContainer": {
        textAlign: "center",
      },

      "& .removeTitle": {
        fontSize: "20px",
        fontWeight: 500,
        color: "#FF0B0BDE",
        marginTop: "20px",
        textAlign: "center",
      },
      "& .removeTitle1": {
        fontSize: "20px",
        fontWeight: 500,
        color: "#000",
        marginTop: "20px",
        textAlign: "center",
      },

      "& .acceptTitle": {
        fontSize: "20px",
        fontWeight: 500,
        color: "#02AB97DE",
        marginTop: "20px",
        textAlign: "center",
      },

      "& .text": {
        fontSize: "20px",
        fontWeight: 400,
        color: "#636880",
        maxWidth: "300px",
        textAlign: "center",
        margin: "20px auto",
      },
      "& .text1": {
        fontSize: "20px",
        fontWeight: 400,
        color: "#636880",
        maxWidth: "350px",
        textAlign: "center",
        margin: "20px auto",
      },

      "& .btnContainer": {
        gap: "20px",
        marginTop: "40px",

        "& .nobtn": {
          width: "100%",
          maxWidth: "156px",
          height: "50px",
          fontSize: "16px",
          // background: "#F2F2F2",
          // color: theme.palette.secondary,
          fontWeight: 400,
          fontFamily: "Public Sans",
          border: "none",
        },

        "& .yesbtn": {
          width: "100%",
          maxWidth: "156px",
          height: "50px",
          // background: "#0856CC",
          // color: "#fff",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Public Sans",
          border: "none",
        },
      },
    },
  },
}));

export default function CommonPopup({
  type,
  title,
  text,
  open,
  onClose,
  onConfirm,
  RemoveHandler,
}) {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleNoButtonClick = () => {
    onClose();
  };

  return (
    <>
      {type === "logout1" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="containedPrimary"
                  className="nobtn"
                  // onClick={handleNoButtonClick}
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedSecondary"
                  className="yesbtn"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.removeItem("token");
                    auth.userLogIn(false, "");
                    auth.setProfileData("");
                    window.location.reload();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}
      {type === "rejectEndorse" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="containedPrimary"
                  className="nobtn"
                  // onClick={handleNoButtonClick}
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedSecondary"
                  className="yesbtn"
                  onClick={() => {
                    RemoveHandler();
                    onClose();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "addLearning" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <ImCross className="crossIcon" onClick={onClose} />
            <Box className="dialogContainer">
              {/* <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div> */}

              <Typography variant="body2" className="removeTitle1">
                {title}
              </Typography>

              <Typography
                variant="body2"
                className="text1"
                style={{ maxWidth: "410px" }}
              >
                {text}
              </Typography>
              <Box className="displayCenter logoPic">
                <img src="/images/logo.svg" alt="" />
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "success" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <ImCross className="crossIcon" onClick={onClose} />
            <Box className="dialogContainer">
              {/* <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div> */}

              <Typography
                variant="body2"
                className="removeTitle1"
                style={{
                  fontSize: isMobile ? "20px" : "30px",
                  lineHeight: isMobile ? "1.4" : "1.6",
                }}
              >
                {title}
              </Typography>

              <Box
                className="displayCenter logoPic"
                style={{ marginTop: "34px" }}
              >
                <img src="/images/logo.svg" alt="" />
              </Box>
            </Box>
          </Dialog>
        </>
      )}
      {type === "remove" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="containedPrimary"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedSecondary"
                  className="yesbtn"
                  onClick={() => {
                    RemoveHandler();
                    onClose();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "delete" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <CloseIcon className="crossIcon" onClick={onClose} />
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography
                variant="body2"
                className="text"
                style={{ lineHeight: "24px", maxWidth: "500px" }}
              >
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="containedPrimary"
                  className="nobtn"
                  onClick={() => {
                    onClose();
                    history.push("/profile");
                  }}
                >
                  Keep My Profile
                </Button>

                <Button
                  variant="containedSecondary"
                  className="yesbtn"
                  style={{ maxWidth: "165px" }}
                  onClick={() => {
                    RemoveHandler();
                    onClose();
                  }}
                >
                  Delete My Profile
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}
      {type === "delete1" && (
        <>
          <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialogMain}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <CloseIcon className="crossIcon" onClick={onClose} />
            <Box className="dialogContainer">
              {/* <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div> */}

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography
                variant="body2"
                className="text"
                style={{ lineHeight: "24px", maxWidth: "500px" }}
              >
                {text}
              </Typography>
              <Box className="displayCenter logoPic">
                <img src="/images/logo.svg" alt="" />
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "accept" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="acceptTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedPrimary"
                  className="yesbtn"
                  onClick={() => onConfirm()}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "decline" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedPrimary"
                  className="yesbtn"
                  onClick={() => onConfirm()}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "logout" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="containedPrimary"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedSecondary"
                  className="yesbtn"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.removeItem("token");
                    auth.userLogIn(false, "");
                    auth.setProfileData("");
                    history.push("/");
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "unsubscribe" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="/images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text1">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="containedPrimary"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button
                  variant="containedSecondary"
                  className="yesbtn"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
}
